export const pt = {
  lang: "pt",
  messages: {
    "Create {{appName}} account": "Criar conta {{appName}}",
    "USER GUIDE": "GUIA UTILIZADOR",
    FAQs: "FAQs",
    "Text too small.": "Texto demasiado pequeno",
    "Invalid text.": "Texto inválido",
    "Invalid email address": "Email inválido",
    "Email address too small": "Email demasiado pequeno",
    "The password must contain both numbers and letters (may have special chars, spaces not allowed)":
      "A password deve conter números e letras (pode ter caracteres especiais, espaços não são permitidos)",
    "Password must be at least 8 characters long.": "A password deve ter no mínimo 8 caracteres.",
    "Upload file": "Upload ficheiro",
    "The username must only contain alphanumeric characters.":
      "Username só pode conter caracteres alfanuméricos ",
    "Username must have at least 4 characters.":
      "Username tem de ter no mínimo 4 caracteres",
    Username: "Username",
    "New Password": "Nova password",
    "Reset password": "Redefinir password",
    "Repeat password": "Repetir password",
    "Your account has been created successfully!":
      "A sua conta foi criada com sucesso",
    "The username is already taken, please choose other.":
      "O nome de usuário já está em uso, escolha outro.",
    "You will receive an email as soon as the account becomes active.":
      "Irá receber um email assim que a sua conta ficar ativa.",
    SEND: "ENVIAR",
    "Finish creating {{appName}} account":
      "Finalizar criação de conta {{appName}}",
    "Thank you for confirming your email address.":
      "Obrigado por confirmar o seu endereço de email.",
    "Please define your credentials and your identity verification.":
      "Por favor defina as suas credenciais e a sua verificação de identidade.",
    "<strong>Case-sensitive username.</strong><p>If there is already a username equal to the one chosen we will add a number at the end.</p><p>Example: username1</p>":
      "<strong>Username sensível a maiúsculas.</strong><p>Se já existir um username igual ao escolhido iremos adicionar um número no final.</p><p>Exemplo: username1</p>",
    "<strong>The password must contain: Numbers and Letters.</strong><p>May have special characters.</p><p>Spaces are not allowed.</p>":
      "<strong>A password deve conter: Números e Letras.</strong><p>Pode ter caracteres especiais.</p><p>Espaços não são permitidos.</p>",
    "{{appName}} account": "Conta {{appName}}",
    Identification: "Identificação",
    "Identification number": "Número de Identificação",
    "Identification document": "Documento de Identificação",
    Address: "Morada",
    "Post code": "Código postal",
    City: "Cidade",
    "Proof of address": "Comprovativo de morada",
    "<p>Dated less than 3 months.</p>": "<p>Com data inferior a 3 meses.</p>",
    "Registration successful!": "Registro efetuado com sucesso!",
    "Please check your email to confirm your identity.":
      "Por favor verifique o seu email para confirmar a sua identidade",
    '(Please check in the "Spam" box)':
      '(Veja também na caixa de "Spam", por favor)',
    Name: "Nome",
    Surname: "Sobrenome",
    Country: "País",
    Currency: "Moeda",
    "Expected monthly volume": "Volume mensal previsto",
    "Interface page for register on <a href='{{url}}' target='_blank'>{{url}}</a> bettingplatform":
      "Página de interface para registro na plataforma de apostas <a href='{{url}}' target='_blank'>{{url}}</a>",
    "It only takes a minute": "Demora apenas um minuto",
    "We detected that you already have a {{appName}} account and we now resend an email with your details.":
      "Detectámos que você já tem uma conta e reenviamos agora um email com os seus dados.",
    'Please check your email to confirm your identity (Please check in the "Spam" box)':
      'Por favor verifique o seu email (Veja também na pasta de "Spam", por favor).',
    "Register {{name}}": "Registro {{name}}",
    "Password updated successfully!": "Password atualizada com sucesso!",
    "Login at <a href='{{url}}'>{{url}}</a> and start betting!":
      "Aceda a <a href='{{url}}'>{{url}}</a> e comece a apostar!",
    "This username does not match the current password reset token.":
      "Este username não corresponde ao token atual de redefinição de password.",
    "Welcome to Pro.{{name}}.com betting platform. The most respected Asian bookmakers, where winners are welcome.":
      "Bem-vindo à plataforma de apostas Pro.{{name}}.com. As casas de apostas asiáticas mais respeitadas, onde os vencedores são bem-vindos.",
    "Register a new account on Pro.{{name}}":
      "Registre uma nova conta no Pro.{{name}}",
    "id-card-name-BR": "RG ou CNH",
    "id-card-name-EN": "Passaporte ou ID Local",
  },
};

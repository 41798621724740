import React, { Component } from "react";
import styles from "./footer.module.css";
import { _t } from "src/redux/store";
import renderHTML from "html-react-parser";

class Footer extends Component {
  render() {
    const currentYear = new Date().getFullYear();
    return (
      <div className={styles.container}>
        <p>
          @{process.env.REACT_APP_TITLE} [2008 - {currentYear}]
        </p>
        <p>{process.env.REACT_APP_SUPPORT_EMAIL}</p>
        <p className={styles.link}>
          {renderHTML(
            _t.translate(
              "Interface page for register on <a href='{{url}}' target='_blank'>{{url}}</a> bettingplatform",
              { url: process.env.REACT_APP_PLATFFORM_URL as string }
            )
          )}
        </p>
      </div>
    );
  }
}

export default Footer;

import { Options } from "./model/myTypes";

const ALLOWED_COINS: Options = {
  EUR: "EUR (€)",
  USD: "USD ($)",
  GBP: "GBP (£)",
  BRL: "BRL (R$)",
};

export function getCoins(): Options {
  const currenciesString = process.env.REACT_APP_CURRENCIES as string;
  const list: string[] = currenciesString.split(",");
  let result: Options = {};
  list.forEach((ccy) => {
    result[ccy] = ALLOWED_COINS[ccy];
  });
  return result;
}

export const LANGUAGES: Options = { en: "gb", pt: "br" };

export const VOLUME: Options = {
  "0 - 99,999": "0 - 99,999",
  "100,000 - 499,999": "100,000 - 499,999",
  "1,000,000 - 4,999,999": "1,000,000 - 4,999,999",
  "5,000,000+": "5,000,000+",
};

export const en = {
  lang: "en",
  messages: {
    "Create {{appName}} account": "Create {{appName}} account",
    "USER GUIDE": "USER GUIDE",
    FAQs: "FAQs",
    "Text too small.": "Text too small.",
    "Invalid text.": "Invalid text.",
    "Invalid email address": "Invalid email address",
    "Email address too small": "Email address too small",
    "The password must contain both numbers and letters (may have special chars, spaces not allowed)":
      "The password must contain both numbers and letters (may have special chars, spaces not allowed)",
    "Password must be at least 8 characters long.":
      "Password must be at least 8 characters long.",
    "Upload file": "Upload file",
    "The username must only contain alphanumeric characters.":
      "The username must only contain alphanumeric characters.",
    "Username must have at least 4 characters.":
      "Username must have at least 4 characters.",
    Username: "Username",
    "New Password": "New Password",
    "Reset password": "Reset password",
    "<strong>The password must contain both numbers and letters</strong><p>May have special chars.</p><p>Spaces not allowed.</p>":
      "<strong>The password must contain both numbers and letters</strong><p>May have special chars.</p><p>Spaces not allowed.</p>",
    "Repeat password": "Repeat password",
    "The username is already taken, please choose other.":
      "The username is already taken, please choose other.",
    "Your account has been created successfully!":
      "Your account has been created successfully!",
    "You will receive an email as soon as the account becomes active.":
      "You will receive an email as soon as the account becomes active.",
    SEND: "SEND",
    "Finish creating {{appName}} account":
      "Finish creating {{appName}} account",
    "Thank you for confirming your email address.":
      "Thank you for confirming your email address.",
    "Please define your credentials and your identity verification.":
      "Please define your credentials and your identity verification.",
    "<strong>Case-sensitive username.</strong><p>If there is already a username equal to the one chosen we will add a number at the end.</p><p>Example: username1</p>":
      "<strong>Case-sensitive username.</strong><p>If there is already a username equal to the one chosen we will add a number at the end.</p><p>Example: username1</p>",
    "<strong>The password must contain: Numbers and Letters.</strong><p>May have special characters.</p><p>Spaces are not allowed.</p>":
      "<strong>The password must contain: Numbers and Letters.</strong><p>May have special characters.</p><p>Spaces are not allowed.</p>",
    "{{appName}} account": "{{appName}} account",
    Identification: "Identification",
    "Identification number": "Identification number",
    "Identification document": "Identification document",
    Address: "Address",
    "Post code": "Post code",
    City: "City",
    "Proof of address": "Proof of address",
    "<p>Dated less than 3 months.</p>": "<p>Dated less than 3 months.</p>",
    "Registration successful!": "Registration successful!",
    "Please check your email to confirm your identity.":
      "Please check your email to confirm your identity.",
    '(Please check in the "Spam" box)': '(Please check in the "Spam" box)',
    Name: "Name",
    Surname: "Surname",
    Country: "Country",
    Currency: "Currency",
    "Expected monthly volume": "Expected monthly volume",
    "It only takes a minute": "It only takes a minute",
    "We detected that you already have a {{appName}} account and we now resend an email with your details.":
      "We detected that you already have a {{appName}} account and we now resend an email with your details.",
    'Please check your email to confirm your identity (Please check in the "Spam" box)':
      'Please check your email to confirm your identity (Please check in the "Spam" box)',
    "Register {{name}}": "Register {{name}}",
    "Interface page for register on <a href='{{url}}' target='_blank'>{{url}}</a> bettingplatform":
      "Interface page for register on <a href='{{url}}' target='_blank'>{{url}}</a> bettingplatform",
    "Password updated successfully!": "Password updated successfully!",
    "Login at <a href='{{url}}'>{{url}}</a> and start betting!":
      "Login at <a href='{{url}}'>{{url}}</a> and start betting!",
    "This username does not match the current password reset token.":
      "This username does not match the current password reset token.",
    "Welcome to Pro.{{name}}.com betting platform. The most respected Asian bookmakers, where winners are welcome.":
      "Welcome to Pro.{{name}}.com betting platform. The most respected Asian bookmakers, where winners are welcome.",
    "Register a new account on Pro.{{name}}":
      "Register a new account on Pro.{{name}}",
    "id-card-name-BR": "Nationl ID or Passport",
    "id-card-name-EN": "Passport or Local ID Card",
  },
};

import { Component } from "react";
import styles from "./header.module.css";
import LanguageSeletor from "./languageSeletor";
import LazyLoad from "react-lazyload";

class Header extends Component {
  render() {
    return (
      <div className={styles.header}>
        <LazyLoad height={72} once>
          <img
            src={`/img/${process.env.REACT_APP_TITLE}-imgs/logo.svg`}
            alt={"logo"}
            className={styles.logoImg}
          />
        </LazyLoad>
        <LanguageSeletor />
      </div>
    );
  }
}

export default Header;

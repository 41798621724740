import { Component } from "react";
import { _t } from "src/redux/store";
import { Helmet } from "react-helmet";

class Head extends Component {
  render() {
    const name = process.env.REACT_APP_TITLE as string;
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {_t.translate(`Register a new account on Pro.{{name}}`, {
            name: name,
          })}
        </title>

        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href={`./img/${name}-imgs/apple-icon-57x57.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href={`./img/${name}-imgs/apple-icon-60x60.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href={`./img/${name}-imgs/apple-icon-72x72.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href={`./img/${name}-imgs/apple-icon-76x76.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href={`./img/${name}-imgs/apple-icon-114x114.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href={`./img/${name}-imgs/apple-icon-120x120.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href={`./img/${name}-imgs/apple-icon-144x144.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href={`./img/${name}-imgs/apple-icon-152x152.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`./img/${name}-imgs/apple-icon-180x180.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="36x36"
          href={`./img/${name}-imgs/android-icon-36x36.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="48x48"
          href={`./img/${name}-imgs/android-icon-48x48.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="72x72"
          href={`./img/${name}-imgs/android-icon-72x72.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="144x144"
          href={`./img/${name}-imgs/android-icon-144x144.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={`./img/${name}-imgs/android-icon-192x192.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`./img/${name}-imgs/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href={`./img/${name}-imgs/favicon-96x96.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`./img/${name}-imgs/favicon-16x16.png`}
        />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content={`./img/${name}-imgs/ms-icon-144x144.png`}
        />
        <meta name="theme-color" content="#ffffff" />
        <link rel="manifest" href={`./img/${name}-imgs/manifest.json`} />
        <meta
          name="description"
          content={_t.translate(
            "Welcome to Pro.{{name}}.com betting platform. The most respected Asian bookmakers, where winners are welcome.",
            {
              name: name,
            }
          )}
        />
      </Helmet>
    );
  }
}

export default Head;

import { Component, Suspense, lazy } from "react";
import {
  Switch,
  Route,
  Redirect,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";
import Head from "./components/helmet/helmet";
import Header from "src/components/header/header";
import Footer from "src/components/footer/footer";

const Register = lazy(() => import("./views/register/register"));
const FinishRegister = lazy(
  () => import("./views/finishRegister/finishRegister")
);
const ChangePassword = lazy(
  () => import("./views/changePassword/changePassword")
);

type Props = RouteComponentProps<{}>;

class App extends Component<Props> {
  render() {
    return (
      <div className="App">
        <Head />
        <Header />
        <Suspense fallback={<div className="loader"></div>}>
          <Switch>
            <Route path="/" exact component={Register} />
            <Route path="/user-confirmation" component={FinishRegister} />
            <Route path="/user-forgot" component={ChangePassword} />
            <Redirect to="/" />
          </Switch>
        </Suspense>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);

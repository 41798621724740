import { MenuItem, Select } from "@material-ui/core";
import { Component } from "react";
import { connect } from "react-redux";
import { LANGUAGES } from "src/app-constants";
import { changeLanguage } from "src/redux/actions/action-settings";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import styles from "./languageSeletor.module.css";
import { ReactComponent as ExpandIcon } from "src/icons/expand_more_white_24dp.svg";
import LazyLoad from "react-lazyload";
import FlagIcon from "src/utils/flagIcon";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class LanguageSeletor extends Component<Props> {
  handleOnChange = (value: string) => {
    this.props.changeLanguage(value);
    document.location.reload();
  };

  renderOptions() {
    return Object.keys(LANGUAGES).map((value, index) => (
      <MenuItem
        key={value + "_" + index}
        classes={{ root: styles.option }}
        value={value}
      >
        <LazyLoad once>
          <FlagIcon className={styles.fladIcon} code={LANGUAGES[value]} />
        </LazyLoad>
      </MenuItem>
    ));
  }

  render() {
    return (
      <div className={styles.formControl}>
        <Select
          classes={{
            root: styles.select,
            icon: styles.expandIcon,
          }}
          className={styles.selectWrapper}
          value={this.props.lang}
          onChange={(e) => this.handleOnChange(e.target.value as string)}
          MenuProps={{ classes: { list: styles.list } }}
          disableUnderline
          IconComponent={ExpandIcon}
        >
          {this.renderOptions()}
        </Select>
      </div>
    );
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE) => {
  return {
    lang: state.settings.userLang,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    changeLanguage: (lang: string) => {
      dispatch(changeLanguage(lang));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSeletor);

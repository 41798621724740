export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  let stateSettingsReducer = state.settings;

  const stateReduced = {
    settings: stateSettingsReducer,
  };

  const serializedState = JSON.stringify(stateReduced);
  localStorage.setItem("state", serializedState);
};

export function clearStorage() {
  localStorage.removeItem("state");
}

export function clearShouldClearCacheFlag() {
  localStorage.removeItem("shouldClearCache");
}

export function getShouldClearCacheFlag() {
  return localStorage.getItem("shouldClearCache");
}

export function setShouldClearCacheFlag(value) {
  localStorage.setItem("shouldClearCache", value);
}

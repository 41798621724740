import { Register, Settings } from "src/model/myTypes";

export const InitialStateSettings: Settings = {
  userLang: process.env.REACT_APP_DEFAULT_LANG as string,
  // navigator.language.indexOf("-") !== -1
  //   ? navigator.language.split("-")[0]
  //   : navigator.language,
};
export const InitialStateRegister: Register = {
  registerStartResponse: false,
  registerStartMessage: "",
  usernameFree: false,
  isValidHash: true,
  hash: "",
  registerContinueResponse: false,
  registerContinueMessage: "",
  isValidHashPassword: true,
  passwordRedefineMessage: "",
  passwordRedefineSuccess: false,
};

export const INITIAL_STATE = {
  register: InitialStateRegister,
  settings: InitialStateSettings,
};
